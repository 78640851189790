import { http } from "../../../common/services/web-backend";
// import { parseResponse} from "../../../common/helpers/responses/parser";


export class trackingService {
    async getDocumentFollow(params){
        try {
            const getTracnkingInfo = await http.get(`documents-followup/getDetailsDocument/${params}`);
            const response = getTracnkingInfo.data
            return response.data
        }catch (e) {
            if (e.response){
                throw this.response
            }else{
                throw e
            }
        }
    }

    async getDocumentTask(params){
        try{
            const getTrackingTask = await http.get(`https://backend.erp.dev.minicode.com.do/api/v1/ecommerce/task/getTaskByDocumentId/${params}?disable_app_mutator=true`);
            const response = getTrackingTask.data
            return response.data
        }catch(e){
            if(e.response){
                throw this.response
            } else {
                throw e
            }
        }
    }
}