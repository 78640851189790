<template>
<div>
    <div class="d-flex p-2 pe-0 align-items-center justify-content-between">
        <h6 class="title-18 weight-500 m-0" v-if="order">
            {{$t("account.your_order_has_been")}} -
            <span class="mt-1 mt-md-0 d-block d-md-inline weight-600" :style="{color : orderStatus.background_color || 'var(--main-red)'}">
                {{checkAddres }}
            </span>
        </h6>
        <div v-if="checkTrackOrder" class="d-flex justify-content-between" style="margin-left: 15px;">
            <button class="d-flex border align-items-center btn-refresh" @click="refreshTimeLine">
                <icon-refresh :size="16" :class="{active: loading}" class="icon-refresh"/>
                <span class="mb-0 ms-1 color-blue-600 cursor-pointer title-14">Refresh</span>
            </button>
        </div>
    </div>
    <template v-if="!loading">
        <ul v-if="checkTrackOrder" ref="content" class="steps mt-5 mb-5" id="steps">
            <li class="li" :id="action.order" style="margin-top: 30px;" :class="[getState(action).complete ? 'complete' : '']" v-for="action in orderTracking.states_available" :key="action.id">
                <div class="status">
                    <div class="circle d-flex align-items-center justify-content-center" v-if="getState(action).complete" :style="{background : getState(action).background_color}">
                        <!-- <eva-icon name="checkmark" v-if="getState(action)" fill="white" width="18px"></eva-icon> -->
                        <icon-check name="checkmark" v-if="getState(action)" :color="'#fff'" :size="15"></icon-check>
                    </div>
                    <div class="circle d-flex align-items-center justify-content-center" v-else :style="{background : getState(action).background_color}">
                        <!-- <eva-icon name="checkmark" v-if="getState(action)" fill="white" width="18px"></eva-icon> -->
                        {{ action.order }}
                    </div>
                    <div class="d-flex flex-column subtite-14 align-items-center mt-3">
                        <h6  class="mb-2 text-nowrap" :style="{color : getState(action).background_color}"> 
                            {{action.name}}
                        </h6>
                        <!-- <span class="size-16" :class="[getState(action).complete ? 'visible' : 'invisible', parseInt(getState(action).time_elapsed) > parseInt(getState(action).time_sla_sec) ? 'text-danger' :'text-success']">
                            <vue-custom-tooltip :label="`${getTimeComplete(getState(action).time_elapsed)} / ${getTimeComplete(getState(action).time_sla_sec)}`" :size="'is-small'"
                            :class="[parseInt(getState(action).time_elapsed) > parseInt(getState(action).time_sla_sec) ? 'bg-tooltip-danger' : 'bg-tooltip-success']">
                                <b>{{getTime(getState(action).time_elapsed)}} / {{getTime(getState(action).time_sla_sec)}}</b>
                            </vue-custom-tooltip>
                        </span> -->
                        <div class="date text-center size-13 text-limited" :class="[getState(action).complete ? 'visible' : 'invisible']">
                            {{getState(action).complete ? getState(action).shortDate : 'N/A'}} 
                            <h6 class="weight-700 size-13">
                                {{getState(action).complete ? getState(action).hour : 'N/A'}}
                            </h6>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div v-if="checkTrackOrderDelivery" class="carrier-details" style="padding: 30px;">
            <div class="carrier-info">
                <img :class="{'positionUPS' : order.order_primary.shipment.provider == 'UPS' && order.order_primary.shipment.servicelevel.length > 11}"  :src="order.order_primary.shipment.provider_image_200" class="carrier-image" alt="Carrier Image" />
                <div class="carrier-name title-16 weight-50"><span :class="{'positionText' : order.order_primary.shipment.provider == 'UPS' && order.order_primary.shipment.servicelevel.length > 5}">{{ order.order_primary.shipment.servicelevel | textFormat }}</span></div>
                </div>
                <div class="tracking-info">
                <div class="tracking-number title-16 weight-500">{{'Tracking Number:' | textFormat}} <span class="title-14 main-link" @click="openLink(order.order_primary.shipment.tracking_url_provider)">{{ order.order_primary.shipment.tracking_number }}</span></div>
                <div class="tracking-status title-16 weight-500">{{'Tracking Status:' | textFormat}} <span class="title-14 color-inactive-main-navy">{{ order.order_primary.shipment.tracking_status | textFormat}}</span></div>
                <div class="provider title-16 weight-500">{{'Provider:' | textFormat}} <span class="title-14 color-inactive-main-navy">{{ order.order_primary.shipment.provider | textFormat }}</span></div>
                <!-- <div class="duration-terms title-16 weight-500">{{'Duration Terms:' | textFormat}} <span class="title-14 color-inactive-main-navy">{{ order.order_primary.shipment.duration_terms | textFormat }}</span></div> -->
            </div>
        </div>

        <!-- <track-task-order :order="order" v-if="!loading"/> -->
        <div v-if="checkTrackOrder"  class="table-responsive my-custom-scrollbar">
            <table class="table">
                <thead>
                    <tr style="border-bottom: 2px solid var(--gray-50);">
                        <th class="size-18 weight-500 color-dark-gray-300 custom-header-padding-left" style="max-width: 170px;">{{$t('account.order_tracket')}} #{{ order.order_token_id }} </th>
                        <th class="size-18 weight-500 color-dark-gray-300 text-center">{{$t('account.state')}}</th>
                        <th class="size-18 weight-500 color-dark-gray-300 text-center pe-5">{{$t('account.location_tracket')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(log, index) in orderTracking.actions_log" :key="index">
                        <td class="align-middle px-0 py-4" style="max-width: 170px;">
                            <div class="d-flex flex-column left-custom-padding">
                                {{ $d(new Date(log.date_time), 'long') | textFormat }}
                                <span class="subtitle-13 color-dark-gray-200">{{ new Date(log.date_time) | formatHour}}</span>
                            </div>
                        </td>
                        <td class="align-middle px-0">
                            <order-status class="mx-auto" :orderStatus="log.name" :style="{color : log.background_color}" />
                        </td>
                        <td class="subtitle-14 align-middle text-center pe-5">
                            {{ filterBranch.description | textFormat }}
                        </td>
                    </tr>
                    <slot />
                </tbody>
            </table>
            <icon-no-product v-if="!orderTracking.actions_log" class="iconStyle" :size="110"/>
        </div>
        <div v-if="checkTrackOrderDelivery"  class="table-responsive my-custom-scrollbar">
            <table class="table">
                <thead>
                    <tr style="border-bottom: 2px solid var(--gray-50);">
                        <th class="size-18 weight-500 color-dark-gray-300 custom-header-padding-left" style="max-width: 170px;">{{$t('account.order_tracket')}} #{{ order.order_token_id }} </th>
                        <th class="size-18 weight-500 color-dark-gray-300 text-center">{{$t('account.state')}}</th>
                        <th class="size-18 weight-500 color-dark-gray-300 text-center pe-5">{{$t('account.location_tracket')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(log, index) in order.order_primary.shipment.tracking_history" :key="index">
                        <td class="align-middle px-0 py-4" style="max-width: 170px;">
                            <div class="d-flex flex-column left-custom-padding">
                                {{ $d(new Date(log.status_date), 'long') | textFormat }}
                                <span class="subtitle-13 color-dark-gray-200">{{ getHour(log.object_created)}}</span>
                            </div>
                        </td>
                        <td class="align-middle px-0">
                            <order-status class="mx-auto" :orderStatus="log.status" />
                        </td>
                        <td class="subtitle-14 align-middle text-center pe-5" :style="{ 'word-wrap': log.status_details.length > 50 ? 'break-word' : 'normal' }">
                            {{ log.status_details | textFormat }}
                        </td>
                    </tr>
                    <slot />
                </tbody>
            </table>
            <icon-no-product v-if="!order.order_primary.shipment" class="iconStyle" :size="110"/>
        </div>
    </template>
    <div class="d-flex justify-content-center align-items-center h-100" style="margin-top: 45px" v-else>
        <half-circle-spinner :animation-duration="1000" :size="40" color="var(--main-red)"/>
    </div>
</div>
</template>
<script>
import IconCheck from '../../../../common/svg/iconCheck.vue';
import OrderStatus from "./OrderStatus.vue"
// import TrackTaskOrder from './TrackTaskOrder.vue';
import { trackingService } from "../orders/./../../services/tracking"
const service = new trackingService();
import { mapGetters } from "vuex"
import moment from "moment";
import iconRefresh from '../../../../common/svg/iconRefresh';
import { HalfCircleSpinner } from 'epic-spinners'
import iconNoProduct from '../../../products/assets/svg/iconNoProduct.vue';
// import OrderNameVue from './OrderName.vue';

export default {
    name:'TrackOrder',
    props: {
        showModal: {
            type: Boolean
        },
        order: {
            type:Object
        },
        filterStateOrderById:{
            type: Object
        }
    },
    computed:{
        ...mapGetters({
            branches: "branches/branches",
        }),
        filterBranch(){
            return this.$options.filters.filterBranch(this.branches, this.order.branch_code)
        },
        checkAddres(){
            return this.order.order_primary?.optionaddress?.address?.address_line ? this.order.order_primary?.shipment?.tracking_status  : this.$t("cart." + this.orderStatus ? this.orderStatus.name : '')
        },
        checkTrackOrder(){
            return this.order?.order_primary?.option_name != 'nacional_delivery' ? true : false
        },
        checkTrackOrderDelivery(){
            return this.order?.order_primary?.option_name == 'nacional_delivery' ? true : false
        }
    },
    components:{
        OrderStatus,
        IconCheck,
        iconRefresh,
        // TrackTaskOrder,
        HalfCircleSpinner,
        iconNoProduct
        // OrderNameVue
    },
    data() {
        return {
            steps: [
                {title: "cobrado", value: 1},
                {title: "account.in_process", value: 2},
                {title: "account.processed", value: 3},
                {title: "account.dispatched", value: 4},
                {title: "account.sent", value: 5},
                {title: "account.delivered", value: 6},
                // {title: "account.canceled", value: 0},
                // {title: "account.on_held", value: 5},
            ],
            orderTracking: [],
            orderStatus: [],
            // orderTrackingTask: [],
            loading: false,
            scrollSpeed: 3,
            interval: 0,
        }
    },
    methods: {
        openLink(link) {
            const url = link;

            window.open(url, '_blank');
        },
        refreshTimeLine(){
            clearInterval(this.interval);
            this.loading = true
            this.getTrackingNow()
            this.startScroll();
        },
        getState(value){
            let filter = this.orderTracking.actions_log.filter(action => action.id === value.id)
            if(filter[0]){
                return {...filter[0], shortDate: this.$d(new Date(filter[0].date_time)), hour: this.getHour(filter[0].date_time), complete: true}
            }else{
                return {background_color: '#e9ecef', complete: false}
            }
        },
        getHour(data){
            const date = new Date(data);
            const hour = date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            return hour
        }, 
        getTime(seconds){
            let negative = false
            if(seconds < 0){
                negative = true
                seconds = Math.abs(seconds)
            }
            // const formatted = moment.utc(seconds*1000).format('mm:ss');
            // console.log(formatted);
            let minutes = Math.floor(seconds / 60)
            let hour = Math.floor(minutes / 60)
            let days = Math.floor(hour / 24)
            if(days >= 1){
                return `${negative ? '-' :''}${days}d`
            }else if(hour >= 1){
                return `${negative ? '-' :''}${hour}h`
            }else if(minutes >= 1){
                return `${negative ? '-' :''}${minutes}m`
            }else{
                return `${negative ? '-' :''}${seconds || 0}s`
            }
        },
        getTimeComplete(seconds){
            let negative = false
            if(seconds < 0){
                negative = true
                seconds = Math.abs(seconds)
            }
            const formatted = moment.utc(seconds*1000).format('HH:mm:ss');
            var days = moment.duration(moment.utc(seconds*1000)).asDays();
            if(days >= 1){
                return `${negative ? '-' :''}${parseInt(days)}D ${formatted}`
            }else{
                return `${negative ? '-' :''}${formatted || 0}`
            }
        },
        async getTrackingNow(){
            try {
                // this.loading = true                
                const response = await service.getDocumentFollow(this.order.order_token_id || this.order.document_id)
                this.orderTracking = response
                this.orderStatus = this.orderTracking.current_action
                const result = this.orderTracking.states_available.find(({ id }) => id === this.orderStatus.id);
                this.orderStatus = result
                this.loading = true
            } catch (error) {
                const e = {
                        show: true,
                        title: 'account.sorry_has_error',
                        description: error ?  error.response.data.message : 'account.last_time'
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', e)
            } finally {
                this.loading = false
            }
        },
        async startScroll() {
                const activeEvent = this.orderStatus;
                if (!activeEvent) return;

                const container = this.$refs.content;
                const activeElement = container.querySelector(`li[id="${activeEvent.id}"]`);
                if (!activeElement) return;
                    
                const containerWidth = container.offsetWidth;
                const activeElementWidth = activeElement.offsetWidth;
                const activeElementOffsetLeft = activeElement.offsetLeft;
                const scrollTarget = activeElementOffsetLeft - (containerWidth - activeElementWidth) / 2;
                const maxScrollLeft = container.scrollWidth - containerWidth;
                    
                const scrollStep = () => {
                const currentScrollLeft = container.scrollLeft;
                const distance = scrollTarget - currentScrollLeft;
                const direction = Math.sign(distance);
                const scrollAmount = Math.min(Math.abs(distance), this.scrollSpeed) * direction;
            
                container.scrollLeft += scrollAmount;
            
                if (
                    (direction > 0 && container.scrollLeft >= scrollTarget) ||
                    (direction < 0 && container.scrollLeft <= scrollTarget) ||
                    (container.scrollLeft >= maxScrollLeft) ||
                    (container.scrollLeft <= 0)
                ) {
                    clearInterval(this.interval);
                }
                };
            
                this.interval = setInterval(scrollStep, 10);
            },
    },
    async created(){
        if(this.order?.order_primary?.option_name != 'nacional_delivery'){
            await this.getTrackingNow()
            await this.startScroll();
        }
    },
    destroyed(){
        clearInterval(this.interval);
    }
}
</script>
<style lang="scss">
    @import '~bootstrap/scss/bootstrap';
    @import '~bootstrap-steps/scss/bootstrap-steps';
    .step-success .step-circle{
        background-color: var(--main-red);
        border-color: var(--main-red);
        color: #fff;
    }
    .step-success .step-text{
        color: var(--main-navy);
    }
    .step-active .step-circle::before{
        color: var(--main-navy);
    }
    .step-active .step-circle{
        background-color: transparent;
        border-color: var(--main-red);
        color: var(--main-red)
    }
    .step-active .step-text{
        color: var(--main-red);
    }
    .step-circle::before{
        width: calc(8.5rem + 1rem - 1.55rem)
    }
    .step-circle{
        border: 1px solid var(--gray-100);
        color: var(--dark-gray-300);
    }
    .step-content{
        width: 8.5rem;
        min-width: 8.5rem;
        max-width: 8.5rem;
    }

    .left-custom-padding {
        padding-left: 1.2rem;
    }
    .custom-header-padding-left {
        padding-left: 1.35rem !important;
    }
    @media (min-width : 768px) {      
        .left-custom-padding {
            padding-left: 2.5rem;
        }
        .custom-header-padding-left {
            padding-left: 2.5rem !important;
        }
    }

@media (max-width : 1200px) {
    .step-circle::before{
        width: calc(6rem + 1rem - 1.55rem)
    }
    .step-content{
        width: 6rem;
        min-width: 6rem;
        max-width: 6rem;
    }
}

@media (max-width: 576px) {
    .step-circle::before{
        width: calc(7rem + 1rem - 1.55rem)
    }
    .step-content{
        width: 7rem;
        min-width: 7rem;
        max-width: 7rem;
    }
}
@media (min-width : 1200px) {
    .step-circle::before{
        width: calc(7.8rem + 1rem - 1.55rem)
    }
    .step-content{
        width: 7.8rem;
        min-width: 7.8rem;
        max-width: 7.8rem;
    }
}
@media (min-width: 1400px) {
    .step-circle::before{
        width: calc(8.5rem + 1rem - 1.55rem)
    }
    .step-content{
        width: 8.5rem;
        min-width: 8.5rem;
        max-width: 8.5rem;
    }

}

.iconStyle{
        display: block;
        margin: 5px auto 0 auto;
        margin-top: 100px;
    }

.icon-refresh.active{
        animation: loading 1s infinite ease-in-out;
    }
@keyframes loading {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }

.btn-refresh{
        border-radius: 20px;
        height: 32px;
        padding: 10px 14px;
        background-color: transparent;
    }
    .btn-refresh:hover{
        border-color: var(--blue-600) !important;
    }
.my-custom-scrollbar {
    position: relative;
    height: 525px;
    overflow: auto;
}

.li {
    transition: all 200ms ease-in;
}

// .timestamp {
//     margin-bottom: 20px;
//     /* padding: 0px 40px; */
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     font-weight: 100;
// }


.status {
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    border-top: 3px solid #e9ecef;
    position: relative;
    transition: all 200ms ease-in;
    /*max-width: 140px;
    min-width: 100px;*/
}
.status h5 {
    font-weight: 600;
}
.circle {
    content: "";
    width: 25px;
    height: 25px;
    // background-color: white;
    border-radius: 25px;
    position: absolute;
    top: -14px;
    /* left: 39%; */
    transition: all 200ms ease-in;
    // border: 1px solid var(--gray-100);
    border: none;
    color: var(--dark-gray-300);
}

.li.complete .status {
    border-top: 3px solid #000000;
}
.li.complete .status:before {
    background-color: #000000;
    border: none;
    transition: all 200ms ease-in;
}
.li.complete .status h4 {
    /* color: #66DC71; */
}
.title{
    max-width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.positionText {
    margin-left: 20px;
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
    // .timeline {
    //     list-style-type: none;
    //     display: block;
    // }

    .li {
        transition: all 200ms ease-in;
        display: flex;
        width: inherit;
    }

    // .timestamp {
    //     width: 100px;
    // }

    .status:before {
        left: -8%;
        top: 30%;
        transition: all 200ms ease-in;
    }
}

.bg-tooltip-danger.vue-custom-tooltip::v-deep::after{
    background-color: #ec4561;
    box-shadow: none;
    font-weight: 600;
    line-height: 12px;
}
.bg-tooltip-danger.vue-custom-tooltip::v-deep::before{
    border-top-color: #ec4561;
}
.bg-tooltip-success.vue-custom-tooltip::v-deep::after{
    background-color: #02a499;
    box-shadow: none;
    font-weight: 600;
    line-height: 12px;
}
.bg-tooltip-success.vue-custom-tooltip::v-deep::before{
    border-top-color: #02a499;
}


    .carrier-details {
    display: flex;
    align-items: center;
    }

    .carrier-info {
    margin-right: 20px;
    }

    .carrier-image {
        width: 100px;
        height: auto;
    }

    .carrier-name {
        margin-top: 5px;
    }

    .tracking-info {
    font-size: 16px;
    }

    .positionUPS{
        display: flex;
        position: relative;
        left: 22px;
        width: 91px !important;
    }
</style>