<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <path class="st4" d="M198.3,137c-18.8,0-34.1,15.3-34.1,34.1s15.3,34.1,34.1,34.1s34.1-15.3,34.1-34.1    C232.4,152.2,217.2,137,198.3,137z M213.9,182.1c0.6,0.6,0.9,1.2,1,1.9c0.2,1-0.2,2.1-0.9,2.8c-0.6,0.6-1.4,0.9-2.3,0.9    c-0.2,0-0.4,0-0.5,0c-0.7-0.1-1.3-0.5-1.9-1l-11-11l-11,11c-0.6,0.6-1.2,0.9-1.9,1c-1,0.2-2.1-0.2-2.8-0.9s-1.1-1.8-0.9-2.8    c0.1-0.7,0.5-1.3,1-1.9l11-11l-11-11c-0.6-0.6-0.9-1.2-1-1.9c-0.2-1,0.2-2.1,0.9-2.8c1.2-1.2,3.3-1.2,4.5,0l11.2,11.2l11.2-11.2    c1.2-1.2,3.3-1.2,4.5,0c0.7,0.7,1.1,1.8,0.9,2.8c-0.1,0.7-0.5,1.3-1,1.9l-11,11L213.9,182.1z"/>
                <path class="st2" d="M156.2,171.1c0-4.2,0.6-8.2,1.8-12h-30.5c-1.1,0-2.1-0.9-2.1-2.1v-10.9c0-1.1,0.9-2.1,2.1-2.1h38.7    c3.2-3.8,7.1-7.1,11.5-9.6H97.5c-1.1,0-2.1-0.9-2.1-2.1v-10.9c0-1.1,0.9-2.1,2.1-2.1H183c1.2,0,2.1,0.9,2.1,2.1v9.7    c4.2-1.4,8.6-2.1,13.2-2.1c0.8,0,1.6,0.1,2.3,0.1V58.6V29.5c0-3.1-2.5-5.6-5.6-5.6h-52.5v49.8l-19.1-12.9l-19.1,12.9V23.9H51.8    c-3.1,0-5.6,2.5-5.6,5.6v135.8c0,7.2,5.8,13,13,13h97.7C156.5,176,156.2,173.5,156.2,171.1z"/>
                <path class="st5" d="M216.5,209c-5.5,2.6-11.7,4.2-18.2,4.2c-8.3,0-16-2.4-22.5-6.6c-15.5-0.6-32.7-0.9-50.8-0.9    c-68.8,0-124.5,4.6-124.5,10.2c0,5.6,55.7,10.2,124.5,10.2s124.5-4.6,124.5-10.2C249.5,213.3,237,210.8,216.5,209z"/>
            </g>
        </g>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconCancelOrder',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>
<style scoped>
	.st2{fill:#212E3D;}
	.st3{display:inline;fill:#4979B2;}
	.st4{fill:#BC2C35;}
	.st5{fill:#F0F4F9;}
</style>