<template>
    <div class="container">
        
    </div>
</template>

<script>
export default {
    name: "Invoice",
    props:{
        products:{
            type: Array
        },
        order:{
            type: Object
        },
        isCart: {
            type: Boolean
        }
    }
}
</script>