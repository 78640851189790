<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" confirm-title="auth.confirm" :deletingItems="orderId" description="account.are_you_sure_you_want_to_delete_this_order">
        <icon-cancel-order :size="80"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconCancelOrder from '../../assets/svg/iconCancelOrder.vue'
export default {
    components: { MainConfirm, IconCancelOrder },
    name: "ConfirmCancelOrder",
    props: {
        orderId:{
            type: String
        }
    }
}
</script>