<template>
    <div class="status-bg px-2" :class="orderStatus">
        <!-- <icon-circle :size="6" class="me-1 mb-1"/> -->
        {{$t(orderStatus)| textFormat}}
    </div>
</template>

<script>
// import IconCircle from '../../../../common/svg/iconCircle.vue';
export default {
    name: "OrderStatus",
    components: {
        // IconCircle,
    },
    data(){
        return {
            status: null,
        }
    },
    props: {
        orderStatus: {
            type: String,
            default: "created",
        }
    },
}
</script>
<style scoped>
    .status-bg{
        width: fit-content;
        white-space: nowrap; 
        border-radius: 20px;
        
        font-size: 14px;
    }
    /* aligning the dot*/
    .status-bg > svg {
        padding-top: 2px; 
    }
</style>