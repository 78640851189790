<template>
    <div class="d-flex flex-column card-order">
        <slot />
        <div class="row m-0 ">
            <div class="d-flex col-md-12 px-0 justify-content-md-between flex-wrap">
                <div class="d-flex align-items-start flex-column px-lg-2 p-2 pt-1">
                    <h5 v-if="order.document_id" class="size-19 weight-600 m-0">
                        <b class="color-main-red weight-700">Order ID</b> #{{ order.document_id }}
                    </h5>
                    <div class="d-flex align-items-center mt-1">
                        <!-- <div :class="order.order_status.name" class="bg-transparent p-0 d-flex align-items-center me-2"
                            v-if="order.order_status">
                            <icon-circle :size="10" />
                        </div> -->
                        <span v-if="order.created_at" class="text-secondary subtitle-15">
                            {{ $t("account.estimated_date_for") }}
                            <b class="weight-600 color-dark-gray-400"> {{ $d(new Date(order.created_at), 'short') |
                        textFormat }}</b>
                        </span>
                    </div>
                </div>
                <div class="actions d-flex p-2 pe-0 align-items-end me-lg-4" :class="{ 'me-xxl-0': isOrderDetails }">
                    <div class="d-flex align-items-center">
                        <a class="pe-3" href="#" @click.prevent="print(order)">{{ isOrderDetails ? $t("account.print") :
                        $t("account.see_invoice") }}</a>
                        <a class="px-3" @click.prevent="buyAgain(order.order_details)">{{ $t("account.buy_again") }} </a>
                        <!-- <a class="ps-3" href="#" @click.prevent="disabledCancelOrder()"
                            v-if="order.order_status.name != 'canceled'">{{ $t(isOrderDetails ? $t("account.cancel") :
                        "account.cancel_an_order") }}</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3 px-lg-3" v-if="isOrderDetails">
                <h1 class="title-18 weight-600">{{ $t("account.general_information") }}</h1>
            </div>
        </div>
            <div class="row align-content-center my-3 mt-lg-4" :class="[isOrderDetails ? 'alignment-padding' : 'mx-0']">
            <router-link v-if="!isOrderDetails" :to="`invoices/${order.document_id}`"
                class="d-flex justify-content-center justify-content-lg-start col-7 col-sm-5 col-md-12 col-lg-3 col-xl-2 ps-2 pb-4 pb-lg-0">
                <wrapper-image :images="order.order_details" :maxWidth="200" />
            </router-link>
            <div class="d-flex flex-column justify-content-center col-6" :class="[isOrderDetails ? 'col-lg-4 ms-xl-4 ps-xl-4' : 'col-md-4 col-lg-2 mx-xl-4 ms-xl-5']">
                <div class="d-flex flex-column align-items-start">
                    <span class="d-lg-none d-xl-block size-17 weight-600 mb-0">{{$t("account.order_date")}}</span>
                    <span class="d-none d-lg-block d-xl-none size-17 weight-600 mb-0">{{$t("account.order")}}</span>
                    <span class="text-secondary subtitle-14 mt-1">{{ $d(new Date(order.created_at), 'short')  | textFormat}}</span>
                </div>
                <div class="d-flex flex-column align-items-start mt-3 text-limited" v-if="order.delivery_detail">
                    <span class="size-17 weight-600 mb-0">{{$t("Send to")}}</span>
                    <a data-toggle="tooltip" data-placement="top" :title="order.address.address_line"  target="_blank" class="subtitle-14 color-blue-600 text-limited text-capitalize" style="max-width: 130px;" v-if="order.address" :href="'https://www.google.com/maps/place/'+order.address.latitud+','+order.address.longitud">
                        {{ order.address.address_line | textFormat}}
                    </a>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center col-6 " :class="[isOrderDetails ? 'col-lg-4 col-xl-4 ps-5' : 'col-md-4 col-lg-2 ms-xl-5 ps-4']">
                <div class="d-flex flex-column align-items-start">
                    <span class="size-17 weight-600 mb-0">{{$t("account.amount")}}</span>
                    <span class="text-secondary subtitle-14 mt-1">{{order.amount | moneyFormat}}</span>
                </div>
                <div class="d-flex flex-column align-items-start mt-3">
                    <span class="size-17 weight-600 mb-0">{{$t("account.quantity")}}</span>
                    <span class="text-secondary subtitle-14 mt-1">{{amountProduct}}</span>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center col-sm-6" :class="[isOrderDetails ? 'col-md-5 col-lg-3 col-xl-3 ps-xl-4 mt-3 mt-lg-0' : 'col-md-4 col-lg-2 mx-xl-4']">
                <div class="d-flex flex-column align-items-start align-middle mt-1">
                    <span class="size-17 weight-600">{{$t("account.state")}}</span>
                    <!-- <order-status class="mt-1" :orderStatus="order.order_status.name"/> -->
                </div>
                <div class="d-flex flex-column align-items-start mt-3 invisible" :class="[isOrderDetails ? 'd-none d-lg-block' : '']">
                    <span class="size-17 weight-600 mb-0">{{$t("account.state")}}</span>
                    <div class="d-flex align-items-center mt-1">
                        <span class="p-0 m-0">Foo</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center align-items-lg-center col-12 col-lg-3 col-xl-2 mt-4 mt-lg-0 "
                v-if="!isOrderDetails">
                <router-link v-if="order" :to="`invoices/${order.document_id}`" class="d-flex">
                    <second-button @click="$emit('getOrderInvoice');" :title="'account.orders_details'" :id="'details'" class="mb-3 de"
                        style="width: 165px;" />
                </router-link>
                <main-button :title="'account.track_order'" @click="showModalTrack = true" class="track-order-btn"
                    style="width: 165px;" />
            </div>
        </div>
        <confirm-cancel-order v-if="showCancel" @confirmDelete="cancelOrder" @closeModal="showCancel = false"
            :orderId="order.order_token_id" />
        <!-- <InvoiceIntranet class="d-none" id="print-invoice" :products="order.order_details" :order="order" /> -->
        <main-modal :title="''" @closeModal="showModalTrack = false" v-if="showModalTrack"
            class="modal-container custom-modal">
            <track-order :orderId="order.order_document_id" :stateOrder="order.order_status_id" :order="order"
                :filterStateOrderById="order.order_status" />
        </main-modal>
    </div>
</template>

<script>
import { productMixin } from "../../../products/helpers/mixin/product-mixin";
import { mapGetters } from "vuex"
import SecondButton from '../../../../common/components/buttons/SecondButton.vue';
import MainButton from '../../../../common/components/buttons/MainButton.vue';
import WrapperImage from '../../../../common/components/images/WrapperImage.vue';
import TrackOrder from './TrackOrder.vue';
import ConfirmCancelOrder from './ConfirmCancelOrder.vue';
// import OrderStatus from "./OrderStatus.vue"
// import IconCircle from '../../../../common/svg/iconCircle.vue';
import MainModal from '../../../../common/components/modals/MainModal.vue';

import { CartService } from "@/modules/cart/services/cart-service";
// import InvoiceIntranet from "../../view/invoiceIntranet.vue";
const service = new CartService();
export default {
    name: "CardOrder",
    props: {
        order: {
            type: Object,
            require: true
        },
        borderOrderList: {
            type: Boolean,
            default: true
        },
        isOrderDetails: {
            type: Boolean,
            default: false
        }
    },
    components: {
        SecondButton,
        MainButton,
        WrapperImage,
        TrackOrder,
        // InvoiceIntranet,
        ConfirmCancelOrder,
        // OrderStatus,
        // IconCircle,
        MainModal,
    },
    mixins: [productMixin],
    computed: {
        ...mapGetters({
            orderStatus: "account/orderStatus",
            branchSelected: "branches/branchSelected",
            configurations: "common/configurations",
        }),
        amountProduct() {
            let total = 0
            for (let i = 0; i < this.order.order_details.length; i++) { total += this.order.order_details[i].quantity }
            return total
        },
    },
    data() {
        return {
            showDatails: false,
            showInvoice: false,
            showCancel: false,
            loading: false,
            error: null,
            showModalTrack: false,
            circleStatus: '',
        }
    },

    methods: {
        getCircleStatus(e) {
            this.circleStatus = e
        },
        formatMonth(month) {
            switch (month) {
                case "01":
                    return 'Enero'
                case "02":
                    return 'Febrero'
                case "03":
                    return 'Marzo'
                case "04":
                    return 'Abril'
                case "05":
                    return 'Mayo'
                case "06":
                    return 'Junio'
                case "07":
                    return 'Julio'
                case "08":
                    return 'Agosto'
                case "09":
                    return 'Septiembre'
                case "10":
                    return 'Octubre'
                case "11":
                    return 'Noviembre'
                case "12":
                    return 'Diciembre'
            }
        },
        isDeliveryService(orderPrimary) {
            return orderPrimary.codplace !== null
        },

        findPlace(codplace) {
            return this.$store.getters['places/places'].find(place => place.codplace === codplace)

        },
        rating(data) {
            this.order['order_details'].map(detail => {
                if (detail.product.codarticulo === data.codarticulo) {
                    detail.product['my_rating'] = { rated: data.rated };
                }
            })
        },
        async buyAgain(products) {
            let newProducts = []
            for (let i = 0; i < products.length; i++) {
                const product = products[i];
                newProducts.push(
                    {
                        cod_articulo: product.product.codarticulo,
                        quantity: product.quantity
                    }
                )
            }
            const response = await service.syncMyCartLocal(newProducts, this.branchSelected.codplace);
            let quantity = 0
            for (let i = 0; i < response.products.length; i++) {
                quantity += response.products[i].quantity
            }
            this.$store.commit("cart/SET_CART", response)
            this.$store.commit("cart/SET_PRODUCTS", response.products)
            let data = { type: 'buyAgain', title: 'account.products_added_to_cart', show: true, quantity: quantity, product: products }
            this.$store.commit("common/SET_NOTIFY", data)
        },
        disabledCancelOrder() {
            if (this.configurations.general.web_page.disable_cancel_order) {
                const warning = {
                    show: true,
                    title: this.$t('checkout.notice'),
                    description: this.$t('checkout.warning_cancel_info'),
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', warning)
            } else {
                this.showCancel = true
            }
        },
        async cancelOrder() {
            try {
                await this.$store.dispatch('account/deleteOrder', this.order.id);
                this.$emit("getOrders")
                this.showCancel = false
            } catch (error) {
                console.log(error);
            }
        },
        async print() {
            this.$store.commit('account/SET_INVOICE', this.order)
            this.$router.push({ path: '/account/invoice-intranet' })
        }
    },
}
</script>

<style scoped>
a {
    font-size: 14px;
    color: var(--blue-600);
}

.actions a:not(:first-child) {
    border-left: 1px solid var(--dark-gray-200);
}

.disabled-nav{
    pointer-events: none;
}

.card-order {
    border: 1px solid rgba(92, 124, 136, 0.199);
    border-radius: 20px;
    padding: 4% 4%;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
    margin: 0;
}

.container-image {
    text-align: center;
    position: relative;
}

.alignment-padding {
    padding-left: 1.3rem;
}

.modal-container.custom-modal::v-deep>.modal-main {
    max-width: none !important;
}

@media (min-width : 768px) {
    .alignment-padding {
        padding-left: 2.4rem;
    }
}

@media (min-width : 992px) {
    .card-order {
        padding: 35px 45px;
    }

    .alignment-padding {
        padding-left: 3.2rem;
    }
}

@media (min-width : 1200px) {
    .alignment-padding {
        padding-left: 0.8rem;
    }
}
</style>
